<script setup lang="ts">
import BaseUiButton from '@dns-modules/base-ui-button/base-ui-button.vue';

useHead({
	title: 'DNS Технологии - IT подразделение DNS',
	meta:  [
		{
			name:    'description',
			content: 'IT подразделение DNS - создает географически распределенные и высоконагруженные сервисы, автоматизирует все бизнес-процессы компании.'
		},
	],
});

const store = useDnsTech();

useAsyncData('materials', () => store.fetchMaterials().then(() => true));

/** Ссылка на html элемент в шаблоне */
const career = ref<HTMLElement>();

/** Материалы */
const materials = computed(() => store.materials);

/** @inheritDoc */
onMounted(() => {
	document.addEventListener('mousemove', moveMouse);
});

/** Движение фигур в зависимости от положения мыши */
function moveMouse(e: MouseEvent) {
	let width  = window.innerWidth / 2;
	let height = window.innerHeight / 2;
	let mouseX = e.clientX;
	let mouseY = e.clientY;
	let depth1 = `${50 - (mouseX - width) * 0.01}% ${50 - (mouseY - height) * 0.02}%`;
	let depth2 = `${50 - (mouseX - width) * 0.02}% ${50 - (mouseY - height) * 0.04}%`;
	let x      = `${depth2}, ${depth1}`;
	if (career.value) {
		career.value.style.backgroundPosition = x;
	}
}
</script>

<template>
	<div class="page-content">
		<section ref="career" class="products-dns" id="career">
			<SliderProducts/>
		</section>
		<Radar/>
		<section class="our-culture" tabindex="0">
			<div class="container">
				<div class="our-culture__inner">
					<div class="our-culture__info">
						<h2 class="our-culture__header">Наша культура, принципы и правила</h2>
						<div class="our-culture__image-block">
							<img class="our-culture__alien" src="/images/various/imgCulture.png" alt="">
						</div>
						<p class="our-culture__text">
							Вокруг нас люди, с которыми приятно работать и достигать амбициозных целей, мы стараемся
							нанимать единомышленников.
							Для этого важно, чтобы вся команда четко представляла и разделяла основные ценности.
						</p>
						<div class="our-culture__list">
							<div class="dns-list-item unit">
								Умение работать в команде
							</div>
							<div class="dns-list-item unit">
								Нацеленность на результат
							</div>
							<div class="dns-list-item unit">
								Ответственность
							</div>
						</div>
						<div class="our-culture__link-container">
							<NuxtLink to="/culture" class="our-culture__button-link">
								<BaseUiButton title="Читать подробнее" class="our-culture__button" :size="'LARGE'"/>
							</NuxtLink>
						</div>
					</div>
					<Alien/>
				</div>
			</div>
		</section>
		<section class="career-dns">
			<div class="container">
				<div class="career-dns__block">
					<nuxt-link class="career-dns__link-work img-block" to="/work">
						<div class="career-dns__link-content">
							<h3 class="career-dns__link-header">Работа</h3>
							<p class="career-dns__link-text">
								У нас сильная, веселая и молодая команда разработчиков, аналитиков и QA специалистов.
								<span class="career-dns__text-hidden">
									Мы используем современные технологии и подходы.
								</span>
							</p>
							<div class="career-dns__link-details">
								<span class="career-dns__link-details details">Подробнее</span>
								<span class="career-dns__link-arrow arrow"></span>
							</div>
						</div>
					</nuxt-link>
					<nuxt-link class="career-dns__link-education img-block" to="/one-c">
						<div class="career-dns__link-content">
							<h3 class="career-dns__link-header">Курсы 1С</h3>
							<p class="career-dns__link-text career-dns__link-text_with-dot">
								Курсы, которые проводит Центр развития робототехники (ЦРР), рассчитаны на тех, кто хочет устроиться работать в компанию DNS Технологии<span
								class="career-dns__text-hidden">
								на должность программиста 1С, но не имеет достаточного опыта или знаний</span>
							</p>
							<div class="career-dns__link-details">
								<span class="career-dns__link-details details">Подробнее</span>
								<span class="career-dns__link-arrow arrow"></span>
							</div>
						</div>
					</nuxt-link>
				</div>
			</div>
		</section>
		<section class="useful-material">
			<div v-if="0 !== materials.length" class="container container__useful-material">
				<MaterialSlider :slides="materials"/>
			</div>
		</section>
	</div>
</template>

<style lang="scss">
@import '../assets/styles/variables/colors';
@import '../assets/styles/dns-it-icons';
@import '../assets/styles/variables/respond-to';

.container {
	&__useful-material {
		@media (max-width: $screen-xs-max) {
			padding: 0;
			margin:  0;
		}
	}
}

.page-content {
	letter-spacing: -.4px;
}

.products-dns {
	padding:             80px 0;
	background-color:    #f7f7f7;
	position:            relative;
	width:               100%;
	background-image:    url('/images/shapes/shapes.png'),
						 url('/images/shapes/shapes-blure.png');
	background-repeat:   no-repeat;
	background-position: center;
	overflow:            hidden;

	@media (max-width: $screen-xs-max) {
		padding:    56px 0 0;
		background: none;
	}
}

.career-dns {
	padding:          80px 0;
	background-color: #fff;
	position:         relative;
	width:            100%;

	@media (max-width: $screen-xs-max) {
		padding: 32px 0 64px !important;
	}

	&__header, &__header-orange {
		font-size:   48px;
		line-height: 1.2;
		font-family: RobotoSlab-Bold, sans-serif;
		text-align:  center;
		padding-top: 70px;
		margin:      0;

		&-orange {
			color: $brand-primary-base;
		}
	}

	&__text {
		text-align:  center;
		margin:      16px 0;
		line-height: 150%;
	}

	&__join-container {
		margin:  0 auto;
		width:   190px;
		display: flex;
	}

	&__join {
		padding: 20px 32px 20px 32px;
	}

	&__block {
		display: flex;
	}

	&__link {
		display: flex;
	}

	.img-block {
		position:        relative;
		transition:      .3s ease;
		width:           555px;
		height:          354px;
		border-radius:   16px;
		overflow:        hidden;
		display:         flex;
		align-items:     flex-end;
		text-decoration: none;
		color:           #fff;
		box-shadow:      0 15px 32px -19px #000;
		padding:         32px;

		&:hover {
			transform:   translateY(3px);
			will-change: auto;
			box-shadow:  none;
		}

		.details {
			transition: .5s ease;
		}

		.arrow {
			transition: color .5s ease;
		}

		&:hover .details,
		&:hover .arrow {
			color: rgba(255, 255, 255, .7);
		}
	}

	&__link-work {
		background:   linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 140%), url('/images/various/office-little.jpg') no-repeat center;
		margin-right: 15px;
	}

	&__link-education {
		background:      linear-gradient(180deg, rgba(255, 227, 88, 0) 0%, #a06103 140%), #ffed00 url('/images/various/1c.png') no-repeat top right;
		background-size: contain;
		margin-left:     15px;
	}

	&__link-header {
		color:         #fff;
		font-size:     24px;
		margin-bottom: 12px;
	}

	&__link-text {
		line-height:    22px;
		letter-spacing: -.4px;
		text-align:     left;
		margin-bottom:  17px;
		color:          #fff;

		&_with-dot {
			&:after {
				content: '.';
			}
		}
	}

	&__text-hidden {
		color: #fff;
	}

	&__link-details {
		display: flex;
		color:   #fff;
	}

	&__link-arrow {
		@extend %dit-arrow-right;
		margin:    5px 0 0 6px;
		font-size: 13px;
		color:     #fff;
	}
}

.our-culture {
	background:     #f9fafc;
	padding-bottom: 80px;

	&__inner {
		display:         flex;
		justify-content: space-between;
	}

	&__header {
		line-height: 47px;
		text-align:  left;
		margin:      100px 0 16px 0;
	}

	&__text {
		margin-bottom: 24px;
		max-width:     630px;
		line-height:   150%;
	}

	&__list {
		margin-left: 20px;
		line-height: 130%;
	}

	&__button-link {
		text-decoration: none;
	}

	&__button {
		& > * {
			font-weight: 700;
			line-height: 24px;
			text-align:  center;
			color:       #fff;
		}
	}

	&__image-block {
		display: none;
	}

	.unit {
		margin-bottom: 24px;

		&:last-child {
			margin-bottom: 32px;
		}
	}

	&__link {
		color:           #fff;
		text-decoration: none;
		font-size:       16px;
		font-weight:     700;
		line-height:     24px;
		padding:         12px 18px;
		border-radius:   8px;
		background:      $brand-orange-gradient;
		text-align:      center;
		margin-bottom:   24px;
		letter-spacing:  0;
		transition:      .2s ease;

		&:hover {
			box-shadow: inset 0 100px 0 rgba(255, 255, 255, .2);
		}

		&:active, &:focus {
			box-shadow: 0 0 0 3px rgba(252, 133, 7, .2), inset 0 100px 0 rgba(0, 0, 0, .05);
		}
	}

	&__img {
		width:      400px;
		height:     100%;
		background: url('/images/various/imgCulture.png') center no-repeat;
		margin-top: 20px;
	}
}

@media (max-width: $screen-lg) { //1200
	.our-culture {
		&__img {
			margin-top: 0;
		}

		&__text {
			width: auto;
		}

		&__header {
			width: max-content;
		}
	}
}

@media (max-width: $container-desktop) { //960
	.our-culture {
		&__header {
			width: auto;
		}
	}
}

@media (max-width: $container-tablet) { //768
	.career-dns {
		padding:             23px 0 64px;
		height:              auto;
		background-color:    #fff;
		background-position: initial;

		&_head {
			padding-bottom: 24px;
		}

		&__block {
			display:         flex;
			flex-wrap:       wrap;
			justify-content: center;
			margin:          0;
		}

		&__join-container {
			width: 100%;
		}

		&__join {
			width: 100%;
		}

		.img-block {
			width: 540px;

			&:last-child {
				margin-top: 16px;
			}
		}

		&__link-work {
			margin-right: 0;
		}

		&__link-education {
			margin-left:     0;
			background-size: auto 100%;
		}
	}

	.our-culture {
		padding: 0;

		&__image-hidden {
			background-image:    url('/images/various/imgCulture.png');
			background-repeat:   no-repeat;
			background-position: center;
			margin:              0 auto;
			height:              59%;
			width:               50%;
		}

		&__container-img {
			display: none;
		}

		&__header {
			text-align: center;
			margin-top: 40px;
		}

		&__link-container {
			width:   100%;
			display: flex;
		}

		&__link {
			width: 100%;
		}

		&__button-link {
			width: 100%;
		}

		&__button {
			width: 100% !important;
		}

		&__image-block {
			display: flex;
		}

		&__alien {
			margin: 0 auto;
		}

		&__text {
			margin-top: 0;
		}

		&__list-item {
			&:last-child {
				margin-bottom: 24px;
			}
		}
	}
}

@media (max-width: $container-phone) { //576
	.page-content .career-dns__header,
	.career-dns__header-orange,
	.our-culture__header {
		font-size:   24px;
		line-height: 31px;
	}

	.page-content .career-dns__link-header {
		font-size: 18px;
	}

	.page-content .career-dns__text,
	.career-dns__link-text,
	.our-culture__text,
	.our-culture__list,
	.our-culture__list-item,
	.unit,
	.career-dns__join {
		font-size: 16px;
	}

	.career-dns {
		background-size:   contain;
		background-repeat: repeat;


		.img-block {
			width:   auto;
			height:  300px;
			padding: 16px;
		}

		&__header {
			padding:     33px 0 8px 0;
			line-height: 1;
		}

		&__text {
			margin:         0;
			padding-bottom: 12px;
		}

		&__link-header {
			margin-bottom: 4px;
		}

		&__text-hidden {
			display: none;
		}

		&__link-text {
			margin:      4px 0 8px 0;
			line-height: 19.5px;
		}

		&__join {
			padding: 10px 0;
		}

		&__link-content {
			margin: 0 16px 16px 16px;
		}
	}

	.our-culture {
		&__text, &__list-item {
			margin-bottom: 12px;
		}

		&__alien {
			width:  300px;
			height: 220px;
		}
	}
}

@media (max-width: 350px) {
	.our-culture {
		padding-top: 24px;

		&__header {
			margin: 0;
		}
	}

	.career-dns {
		.img-block {
			width:  288px;
			height: 200px;
		}
	}
}
</style>
