<script lang="ts" src="./index.ts"></script>

<template>
	<section ref="radar" class="radar" tabindex="0">
		<h2 class="radar__header">Технологический радар</h2>
		<div class="radar__container">
			<ul ref="content" class="radar__content" >
				<RadarItem v-for="(link, index) in techLogoList" :key="index">
					<img class="radar__img" :src="requireImage(link)" :alt="parseText(link)"/>
				</RadarItem>
			</ul>
		</div>
	</section>
</template>

<style lang="scss">
@import "assets/styles/variables/respond-to";

.radar {
	padding-top:     33px;
	overflow:        hidden;
	scroll-behavior: smooth;

	&__header {
		position:      relative;
		margin-bottom: 54px;
		text-align:    center;
		line-height:   47px;
		z-index:       10;
	}

	&__container {
		margin:          auto;
		display:         flex;
		justify-content: space-between;
		max-width:       1300px;
	}

	&__content {
		transition:            all 1s;
		display:               grid;
		grid-template-columns: repeat(auto-fill, 165px);
		grid-auto-rows:        165px;
		grid-gap:              24px;
		justify-content:       center;
		width:                 100%;
		list-style:            none;
	}

	&__img {
		object-fit:     scale-down;
		pointer-events: none;
	}
}

@media (max-width: $screen-tablet) {
	.radar {
		padding: 24px 0;

		&__container {
			display: inherit;
		}

		&__header {
			text-align:    center;
			margin-bottom: 24px;
		}

		&__content {
			grid-template-columns: repeat(auto-fill, 155px);
			grid-gap:              20px;
			grid-auto-rows:        150px;
		}
	}
}

@media (max-width: $screen-phone-breakdown) {
	.radar {
		&__content {
			grid-template-columns: repeat(auto-fill, 84px);
			grid-gap:              8px;
			grid-auto-rows:        84px;
		}
	}
}
</style>
