import DateHelper from '../../helpers/date-helper';
import {generateCountText} from '../../helpers/count-slide-helper';

/** Компонент слайдера. */
export default {
	name: 'material-slider',
	props: {
		slides: {type: Array, default: []},
	},
	setup() {
		/** Элементы слайдера "Полезные материалы" */
		const materialsElements = ref();

		/** {inheritdoc} */
		onMounted(async () => {
			materialsElements.value = document.querySelectorAll('.materials-slide');
		});

		/**
		 * Форматирование даты.
		 *
		 * @param date Дата в формате "YYYY-MM-DD h:mm:ss"
		 */
		const formatDate = (date: string) => {
			return DateHelper.formattedDate(date);
		}

		return {
			materialsElements,
			formatDate,
			generateCountText
		}
	}
}
