<script lang="ts" src="./index.ts"></script>

<template>
	<div class="slider-products">
		<h1 class="slider-products__heading">
			Разрабатываем <br> и масштабируем цифровые продукты
		</h1>
		<div class="slider-products__feedback-button-container">
			<div
				class="slider-products__feedback-button"
				@click="showModal()"
				@keydown.enter="showModal(true)"
			>
				Напишите нам
			</div>
		</div>
		<FlickingSlider
			:sliderName="'slider-products__container'"
			:slides="productsElements"
		>
			<div
				v-for="(slide, index) in slides"
				:key="index"
				class="slide slider-products__item"
			>
				<span class="slider-products__item-count">{{ generateCountText(index, slides.length) }}</span>
				<div v-html="slide.title" class="slider-products__item-title"></div>
				<div v-html="slide.description" class="slider-products__item-description"></div>
			</div>
		</FlickingSlider>
		<FeedbackModal
				v-if="isShowModal"
				:has-re-captcha="true"
				@close-modal="closeModalHandler"
		/>
	</div>
</template>

<style lang="scss">
@import 'assets/styles/variables/_respond-to';
@import '@egjs/flicking/dist/flicking.css';

.slider-products {
	&__heading {
		max-width:   1160px;
		margin:      45px auto 0;
		font-weight: 600;
		font-size:   54px;
		line-height: 62px;
		color:       #333;

		@media (max-width: $screen-lg) {
			max-width: 920px;
		}

		@media (max-width: $screen-md) {
			margin-left: 20px;
		}

		@media (max-width: $screen-xs-max) {
			margin:            0;
			font-size:         24px;
			line-height:       32px;
			text-align:        center;
			letter-spacing:    -.4px;
			background-image:  url('/images/shapes/products-mobile-background.png');
			background-repeat: no-repeat;
		}
	}

	&__container {
		margin:          40px auto auto;
		width:           1160px;
		scrollbar-width: none;
		overflow:        unset !important;

		&::-webkit-scrollbar {
			width:  0;
			height: 0;
		}

		@media (max-width: $container-lg) {
			overflow: auto;
			width:    auto;
		}
		@media (max-width: $screen-md-max) {
			padding:0 20px;
		}

		@media (max-width: $screen-xs-max) {
			margin-top: 28px;
			padding:    0 16px;
		}
	}

	.flicking-camera {
		display:    flex;

		& > :not(:first-child) {
			margin-left: 20px;
		}

		@media (max-width: $screen-xs-max) {
			& > :not(:first-child) {
				margin-left: 16px;
			}
		}
	}

	&__item {
		position:      relative;
		flex-shrink:   0;
		width:         100%;
		max-width:     333px;
		height:        243px !important;
		padding:       45px 50px;
		background:    #fff;
		border:        1px solid rgba(0, 0, 0, .06);
		box-shadow:    0 2px 2px rgba(0, 6, 64, .04);
		border-radius: 30px;

		@media (max-width: $screen-xs-max) {
			max-width: 230px;
			height:    210px !important;
			padding:   28px 20px;
		}
	}

	&__item-count {
		position: absolute;
		width:    0;
		height:   0;
		overflow: hidden;
	}

	&__item-title {
		font-weight: 700;
		font-size:   20px;
		line-height: 26px;
		color:       #333;

		@media (max-width: $screen-xs-max) {
			font-size:   18px;
			line-height: 24px;
		}
	}

	&__item-description {
		margin-top:     8px;
		font-weight:    400;
		font-size:      18px;
		line-height:    24px;
		letter-spacing: -.4px;
		color:          #333;

		@media (max-width: $screen-xs-max) {
			font-size:   16px;
			line-height: 20px;
		}
	}

	&__feedback-button-container {
		padding: 0 16px;
	}

	&__feedback-button {
		margin-top:      12px;
		display:         none;
		height:          44px;
		align-items:     center;
		justify-content: center;
		background:      linear-gradient(0deg, #fc8507 0%, #ffa218 100%);
		border-radius:   12px;
		font-weight:     700;
		line-height:     24px;
		color:           #fff;
		cursor:          pointer;

		@media (max-width: $screen-xs-max) {
			display: flex;
		}
	}
}

.feedback-modal {
	.vm--modal {
		margin: 0;
		left:   0 !important;
	}
}
</style>
