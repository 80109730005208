<script lang="ts" src="./index.ts"></script>

<template>
	<div class="">
		<h2 class="slider__heading">Полезные материалы</h2>
		<template v-if="slides && materialsElements">
			<NuxtLink v-if="slides.length > 8" to="/materials" class="slider__link">Все материалы</NuxtLink>
			<FlickingSlider
				:sliderName="'slider-wrapper'"
				:slides="materialsElements"
			>
				<a
					v-for="(slide, index) in slides"
					:key="index"
					:href="`/materials/${slide.id}`"
					class="slide materials-slide"
					:draggable="false"
				>
					<span class="slide__count">{{ generateCountText(index, slides.length) }}</span>
					<span class="slide__date">{{ formatDate(slide.date) }}</span>
					<h3 class="slide__title">{{ slide.title }}</h3>
					<div class="slide__image-wrapper">
						<img class="slide__image" :src="slide.imageUrl" alt="">
					</div>
				</a>
			</FlickingSlider>
		</template>
	</div>
</template>

<style lang="scss" scoped>
@import 'assets/styles/_dns-it-icons';
@import 'assets/styles/variables/_respond-to';
@import 'assets/styles/variables/colors';
@import '@egjs/flicking/dist/flicking.css';

.slider-wrapper {
	width:   100%;
	padding: 24px 0;
}

.slider {
	display:     flex;
	flex-wrap:   wrap;
	align-items: baseline;

	@include respond-to(phone) {
		justify-content: center;
		padding:         0 16px 6px 16px;
	}

	&__heading {
		flex: 1 0 auto;

		@include respond-to(phone) {
			text-align: center;
		}
	}

	&__link {
		@extend %dit-arrow-right;
		position:        relative;
		padding-right:   22px;
		text-decoration: none;
		color:           #0d61af;

		&:hover {
			color: #fc8507;
		}

		&:before {
			position: absolute;
			right:    0;
			top:      8%;
		}

		@include respond-to(phone) {
			margin-bottom: 18px;
			order:         3;
		}
	}

	&__body {
		display: flex;
	}

	.slide {
		position:        relative;
		display:         flex;
		flex-direction:  column;
		justify-content: space-between;
		max-width:       360px;
		height:          360px;
		padding:         20px;
		background:      #fff;
		border:          2px solid #f4f4f4;
		border-radius:   20px;
		margin-right:    30px;
		text-decoration: none;
		transition:      .5s;

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			box-shadow: 0 20px 22px -19px rgba(0, 0, 0, 0.5);
			z-index:    1;
			transform:  translate(0, -3px);
		}

		@include respond-to(tablet) {
			max-height: 320px;
			width:      261px;
		}

		@include respond-to(phone) {
			max-width:    261px;
			height:       292px;
			margin-right: 16px;
		}

		&__count {
			position: absolute;
			width:    0;
			height:   0;
			overflow: hidden;
		}

		&__date {
			margin-bottom: 16px;
			padding:       8px 12px;
			width:         max-content;
			border:        1px solid #d9d9d9;
			border-radius: 12px;
			background:    #fff;
			color:         #fc8507;
			font-weight:   700;
		}

		&__title {
			font-family:   Calibri, sans-serif;
			flex:          1;
			font-size:     18px;
			font-weight:   700;
			margin-bottom: 16px;
		}

		&__image-wrapper {
			width:         100%;
			overflow:      hidden;
			border-radius: 17px;
		}

		&__image {
			width:          100%;
			height:         100%;
			object-fit:     cover;
			pointer-events: none;
		}
	}
}

.swiper-button-prev,
.swiper-button-next {
	width:            48px;
	height:           48px;
	background-color: #fff;
	border-radius:    50%;
	color:            #8c8c8c;
	font-size:        24px;
	box-shadow:       0 4px 12px rgba(0, 0, 0, 0.2);

	&:after {
		content: '';
	}

	@include respond-to(phone) {
		display: none;
	}
}

.swiper-button-prev {
	@extend %dit-arrow-short-left;
}

.swiper-button-next {
	@extend %dit-arrow-short-right;
}

.swiper-button-disabled {
	display: none;
}
</style>
