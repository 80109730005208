import FeedbackModal from '~/components/feedback-modal.vue';
import {StaticProductInfo} from './static-product-info';
import {generateCountText} from '../../helpers/count-slide-helper'

/** Компонент слайдера-продуктов. */
export default {
	components: {FeedbackModal},
	setup() {
		/** Элементы слайдера "Продукты" */
		const productsElements = ref();

		/** Отображается ли модал ОС */
		const isShowModal = ref(false);

		/** Отрисовка модала */
		const showModal = () => {
			isShowModal.value = true;
		}

		/** Обработчик открытия модала ОС */
		const closeModalHandler = () => {
			isShowModal.value = false;
		}

		/** {inheritdoc} */
		onMounted(() => {
			productsElements.value = document.querySelectorAll('.slider-products__item');
		});

		/** Описание продуктов. */
		const slides = StaticProductInfo;

		return {
			slides,
			productsElements,
			isShowModal,
			generateCountText,
			showModal,
			closeModalHandler
		}
	}
}
