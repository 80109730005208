import logos from '~/components/radar/data/logos';
import RadarTransformElement from '~/components/radar/models/radar-transform-element';

/** Компонент технического радара. */
export default {
	name:       "Radar",
	setup() {
		/** Ссылка на блок с контентом */
		const content = ref();

		/** Ссылка на компонент "Радар" */
		const radar = ref<HTMLElement>();

		/** Высота секции */
		const sectionHeight = ref();

		/** Максимальная высота секции */
		const maxSectionHeight = ref();

		/** Высота сетки элементов секции */
		const contentHeight = ref();

		/** Список логотипов используемых технологий */
		const techLogoList: string[] = logos;

		/** Параметр для замедления скорости параллакс-эффекта */
		const PARALLAX_OFFSET_DIV = 10;

		/** Стартовое значение продолжительности анимации элемента */
		const MIN_TRANSITION_DURATION = 0.6;

		/** Вспомогательное значение для расчета смещения колонки */
		const DELTA_MULTIPLY_PROP = 10;

		/** Коэффициент ограничения максимальной высоты секции */
		const MAX_SECTION_HEIGHT_COEFF = 1.25;

		/** Разрешение экрана для идентификации устройства */
		const MIN_DESKTOP_RESOLUTION = 768;

		/** @inheritDoc */
		onMounted(() => {
			nextTick(() => {
				getHeights();
				if (MIN_DESKTOP_RESOLUTION < window.innerWidth) {
					initParallax();
				}

				window.addEventListener('resize', () => {
					if (radar.value) {
						radar.value.style.height = 'auto';
					}
					getHeights();
					if (MIN_DESKTOP_RESOLUTION < window.innerWidth) {
						initParallax();
					}
				})
			})
		});

		/**
		 * Инициализировать параллакс радара.
		 *
		 * @author Макарова Ольга <makarova.oa@dns-shop.ru>
		 */
		const initParallax = () => {
			const gap         = parseFloat(window.getComputedStyle(content.value, null).getPropertyValue('column-gap'));
			const columnWidth = parseFloat(window.getComputedStyle(content.value, null).getPropertyValue('grid-template-columns'));

			const rowHeight = columnWidth + gap;
			const columns   = Math.floor((content.value.offsetWidth + gap) / rowHeight);

			const oddColumnsNumber  = Math.ceil(columns / 2);
			const evenColumnsNumber = Math.floor(columns / 2);

			const centerValue = columns / 2 - 0.5;

			// -- Разбиваем список карточек радара на два по направлению движения (вверх/вниз)
			// delta рассчитывается по принципу "чем дальше от середины - тем больше"
			const list                               = content.value.querySelectorAll('.radar__item-container');
			const elemsUp: RadarTransformElement[]   = [];
			const elemsDown: RadarTransformElement[] = [];
			for (let i = 0; i < list.length; i++) {
				// определяем четность колонки, в которой расположен элемент
				if (0 === i % columns % 2) {
					const column = 2 * (elemsDown.length % oddColumnsNumber);
					elemsDown.push({
						element: <HTMLElement>list[i],
						delta:   Math.abs(centerValue - column) * DELTA_MULTIPLY_PROP,
					});
				}
				else {
					const column = 1 + 2 * (elemsUp.length % evenColumnsNumber);
					elemsUp.push({
						element: <HTMLElement>list[i],
						delta:   (Math.abs(centerValue - column) + 1) * DELTA_MULTIPLY_PROP,
					});
				}
			}
			// -- -- --

			// -- Расчет продолжительности анимации для строк
			const rows = Math.ceil(techLogoList.length / columns);

			const rowsTransDurations: number[] = [];
			for (let i = 0; i < rows; i++) {
				rowsTransDurations.push(i / rows + MIN_TRANSITION_DURATION);
			}
			// -- -- --

			const parallaxStartPos = (content.value.getBoundingClientRect().top + window.scrollY) - window.innerHeight + rowHeight;

			/** Переменные для определения направления скролла */
			let oldOffset = window.pageYOffset;
			let newOffset;

			/** Флаг сброса позиции к стартовой */
			let defaultPosReady = false;

			const scrollHandler = () => {
				const scrollYFromStart = window.scrollY - parallaxStartPos;
				if (0 < scrollYFromStart) {
					const elemPosOffset = scrollYFromStart / PARALLAX_OFFSET_DIV;

					defaultPosReady = true;

					newOffset      = window.pageYOffset;
					const scrollUp = (0 > oldOffset - newOffset);

					for (let i = 0; i < elemsDown.length; i++) {
						const duration                        = rowsTransDurations[scrollUp ? (rows - Math.floor(i / oddColumnsNumber) - 1) : (Math.floor(i / oddColumnsNumber))];
						elemsDown[i].element.style.transition = 'transform ' + duration + 's ease-out';
						elemsDown[i].element.style.transform  = 'translateY(' + (elemPosOffset + elemsDown[i].delta) + 'px)';
					}

					for (let i = 0; i < elemsUp.length; i++) {
						const duration                      = rowsTransDurations[scrollUp ? (Math.floor(i / evenColumnsNumber)) : (rows - Math.floor(i / oddColumnsNumber) - 1)];
						elemsUp[i].element.style.transition = 'transform ' + duration + 's ease-out';
						elemsUp[i].element.style.transform  = 'translateY(-' + (elemPosOffset + elemsUp[i].delta) + 'px)';
					}

					const dynamicSectionHeight: number = (sectionHeight.value + scrollYFromStart / PARALLAX_OFFSET_DIV);

					if (maxSectionHeight.value > dynamicSectionHeight) {
						if (radar.value) {
							radar.value.style.height = `${dynamicSectionHeight}px`;
						}
					}

					oldOffset = newOffset;

					return;
				}

				// Сброс положения блоков при достижении стартовой позиции.
				if (defaultPosReady) {
					for (let i = 0; i < elemsDown.length; i++) {
						elemsDown[i].element.style.transform = 'none';
					}

					for (let i = 0; i < elemsUp.length; i++) {
						elemsUp[i].element.style.transform = 'none';
					}

					defaultPosReady = false;
				}
			}

			window.addEventListener('scroll', scrollHandler);
			window.addEventListener('resize', () => {
				window.removeEventListener('scroll', scrollHandler);
			});
		}

		/**
		 * Подключение картинки.
		 *
		 * @param src Ссылка на изображение
		 */
		const requireImage = (src: string) => {
			return `/images/radar/${src}`;
		}

		/**
		 * Функция получения текста из названия изображения для атрибута alt.
		 *
		 * @param value Название изображения
		 */
		const parseText = (value: string) => {
			return value.split('.')[0].replace(/[-_]/, ' ');
		}

		/** Функция получения и расчета высот секции и контента. */
		const getHeights = () => {
			sectionHeight.value    = parseFloat(window.getComputedStyle(radar.value, null).getPropertyValue('height'));
			contentHeight.value    = parseFloat(window.getComputedStyle(content.value, null).getPropertyValue('height'));
			maxSectionHeight.value = contentHeight.value * MAX_SECTION_HEIGHT_COEFF;
		}

		return {
			content,
			radar,
			techLogoList,
			requireImage,
			parseText,
		}
	}
}
