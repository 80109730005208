/**
 * Статика для слайдера в блоке "Разрабатываем и масштабируем цифровые продукты"
 *
 * @author Комарицын Михаил <komaricyin.ma@dns-shop.ru>
 */
export const StaticProductInfo = [
	{
		title:       "Разработка ПО",
		description: "Flutter, Android, iOS<br>1C, C#, Net.Core<br> PHP, JS<br> Python, SQL",
	},
	{
		title:       "ИТ-консалтинг",
		description: "От технического аудита<br>приложения до формирования digital стратегии",
	},
	{
		title:       "Поддержка<br>и сопровождение ПО",
		description: "Обеспечиваем бесперебойное функционирования ПО",
	},
	{
		title:       "Дизайн и проектирование интерфейсов",
		description: "Разрабатываем удобные и логичные пользовательские интерфейсы",
	},
	{
		title:       "Разработка платформ анализа данных",
		description: "Услуги в рамках Data Science<br>и Data Engineering компетенций",
	},
	{
		title:       "DevOps сопровождение",
		description: "Настраиваем и поддерживаем стабильную IT инфраструктуру",
	},
	{
		title:       "Контроль качества ПО",
		description: "Проектируем и проводим тесты для мобильных, веб-приложений и API",
	},
	{
		title:       "Продуктовое управление разработкой ПО",
		description: "Развиваем продукты и их ключевые показатели",
	},
	{
		title:       "Управление проектами по разработке ПО",
		description: "Управляем разработкой<br>сервисов и приложений"
	}
];
