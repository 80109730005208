/** Элемент технического радара. */
export default {
	name: "RadarItem",
	setup() {
		/** Элемент радара */
		const cardItem = ref();

		/** Обертка элемента радара */
		const cardWrapper = ref();

		/** Разрешение экрана для идентификации устройства */
		const MIN_DESKTOP_RESOLUTION = 768;

		const isMobile = ref(false);

		/** @inheritDoc */
		onMounted(() => {
			isMobile.value = (MIN_DESKTOP_RESOLUTION > window.innerWidth);
		});

		/** Обработчик наведения / нажатия на элемент */
		const handleStart = (event: MouseEvent) => {
			if (isMobile.value) {
				return;
			}

			cardWrapper.value.classList.add('hover');
			cardItem.value.focus();
			handleMove(event);
		}

		/**
		 * Обработчик движения элемента
		 *
		 * @author Макарова Ольга <makarova.oa@dns-shop.ru>
		 */
		const handleMove = (event: MouseEvent) => {
			if (isMobile.value) {
				return;
			}

			event.preventDefault();

			if (!cardWrapper.value.classList.contains('hover')) {
				return;
			}

			const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

			if (!cardItem.value.querySelector('.reflection') && mediaQuery && !mediaQuery.matches && !isMobile.value) {
				const reflection = document.createElement('span');
				reflection.classList.add('reflection');
				cardItem.value.prepend(reflection);
			}

			let posX = (<MouseEvent>event).offsetX;
			let posY = (<MouseEvent>event).offsetY;

			const width      = cardItem.value.clientWidth;
			const angleY     = (width / 2 - posX) / width * 10;
			const angleX     = (width / 2 - posY) * -1 / width * 10;
			const translateX = ((width / 2 - posX)) * -1 / width * 10;
			const translateY = ((width / 2 - posY)) * -1 / width * 10;

			cardItem.value.style.perspective = (width * 5) + 'px';

			if (mediaQuery && !mediaQuery.matches) {
				cardItem.value.style.transform = `translateZ(4rem) rotateY(${angleY}deg) rotateX(${angleX}deg) translateX(${translateX}px) translateY(${translateY}px)`;
			}

			const content = cardItem.value.querySelector('.radar__item-content');

			if (mediaQuery && !mediaQuery.matches) {
				const modifier                         = !content.classList.contains('reverse') ? -.65 : .2;
				(<HTMLElement>content).style.transform = `translateX(${translateX * modifier * 2}px) translateY(${translateY * modifier * 2}px)`;
			}

			const reflection: HTMLElement | null = cardItem.value.querySelector('.reflection');

			if (reflection && mediaQuery && !mediaQuery.matches) {
				reflection.style.width     = (width * 1.5) + 'px';
				reflection.style.height    = (width * 1.5) + 'px';
				reflection.style.margin    = `${width * -.75}px 0 0 ${width * -.75}px`;
				reflection.style.transform = `translateY(${posY - (width / 2)}px) translateX(${(width * .1) + (posX * .8)}px)`;
			}
		}

		/**
		 * Обработчик окончания движения
		 *
		 * @author Макарова Ольга <makarova.oa@dns-shop.ru>
		 */
		const handleEnd = () => {
			if (isMobile.value) {
				return;
			}

			cardWrapper.value.classList.remove('hover');
			cardItem.value.blur();
			cardItem.value.style.transform = null;

			cardItem.value.querySelector('.radar__item-content').style.transform = null;

			const reflection = cardItem.value.querySelector('.reflection');

			if (reflection) {
				reflection.style.transform = null;
			}

			// Скроллим внутри радара вверх, т.к. фокус на карточке мог переместить видимую область.
			document.querySelector('.radar')!.scrollTo({top: 0, behavior: 'smooth'});
		}

		return {
			cardItem,
			cardWrapper,
			handleStart,
			handleMove,
			handleEnd,
		}
	}
}
