<script lang="ts" src="./index.ts"></script>

<template>
	<li class="radar__item-container" ref="cardWrapper">
		<div
			ref="cardItem"
			class="radar__item"
			@mouseenter="handleStart"
			@mousemove="handleMove"
			@mouseleave="handleEnd"
		>
			<div class="radar__item-content">
				<slot/>
			</div>
		</div>
	</li>
</template>

<style lang="scss">
@import 'assets/styles/variables/respond-to';

.radar {
	&__item-container {
		position: relative;

		&.hover {
			z-index: 2;

			& > .radar__item {
				box-shadow: 0 15px 30px 4px rgba(0, 0, 0, .1);

				@media (max-width: $screen-tablet) {
					box-shadow: none;
				}
			}
		}
	}

	&__item {
		transition:      transform 50ms ease-in-out;
		transform-style: preserve-3d;
		overflow:        hidden;
		outline:         none;
		height:          100%;
		background:      #fff;
		border:          2px solid #f4f4f4;
		border-radius:   20px;
		box-shadow:      0 4px 160px rgba(0, 0, 0, .08);

		@media (prefers-reduced-motion: reduce) {
			transition: none;
		}

		& > .reflection {
			position:         absolute;
			top:              0;
			left:             0;
			pointer-events:   none;
			background-image: radial-gradient(#fff7, transparent 70%);
			transform:        translateY(-100%);
			z-index:          4;
		}
	}

	&__item-content {
		position:            absolute;
		top:                 0;
		right:               0;
		bottom:              0;
		left:                0;
		display:             flex;
		justify-content:     center;
		align-items:         center;
		width:               100%;
		height:              100%;
		background-position: center center;
		transform-style:     preserve-3d;
		background-repeat:   no-repeat;
		transition:          transform 50ms ease-in-out;
		pointer-events:      none;

		@media (prefers-reduced-motion: reduce) {
			transition: none;
		}
	}
}

@media (max-width: $screen-tablet) {
	.radar {
		&__item-content {
			padding: 25px;
		}
	}
}

@media (max-width: $screen-phone-breakdown) {
	.radar {
		&__item-content {
			padding: 15px;
		}
	}
}
</style>
